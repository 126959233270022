<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-sbpm-diary-diary' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Diaries</v-btn
        >
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table-bordered">
              <tbody>
                <tr>
                  <td>Title</td>
                  <td>{{ diary.title }}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td v-html="diary.description"></td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>{{ formatDate(diary.due_date) }}</td>
                </tr>
                <tr>
                  <td>Completed</td>
                  <td>{{ diary.completed ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <td>Assigne User</td>
                  <td v-if="diary.assigne_user">
                    {{ diary.assigne_user.full_name }}
                  </td>
                </tr>

                <tr v-if="diary.user">
                  <td>Created by</td>
                  <td>{{ diary.user.full_name }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="diary.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
              <template v-slot:item.audit_date="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- <DiaryItem ref="diaryItem" /> -->

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Item</v-card-title>
        <v-card-text
          >Are you sure you wish to delete item from list?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import DiaryItem from "./components/DiaryItem.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  //components: { DiaryItem },

  data() {
    return {
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },

      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Order", value: "order" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      breadcrumbs: [
        {
          text: "Diaries",
          disabled: false,
          to: { name: "module-sbpm-diaries" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    diary() {
      return this.$store.getters["sbpm/diariesStore/get"];
    },
  },
  methods: {
    openDelete(item) {
      this.deleteForm.item = item;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.item = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("sbpm/diariesStore/deleteItem", {
          appId: this.$route.params.id,
          diaryId: this.$route.params.diaryId,
          itemId: this.deleteForm.item.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },
  },
};
</script>
